/** @jsx jsx */
import { jsx } from 'theme-ui'
import { PageProps, StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

interface ObjectLiteral {
  [key: string]: string
}

const duplicateRoutes: ObjectLiteral = {
  '/products/bensoleimani.com/products/': '/products',
  '/collections/bensoleimani.com/collections/': '/collections',
}

const pagesRoutes: ObjectLiteral = {
  'bespoke-services-1': 'pages/bespoke-services',
}

const collectionsRoutes: ObjectLiteral = {
  rug: '/collections/rugs/',
  outdoor: '/collections/outdoor/',
  lighting: '/collections/lighting/',
  lamp: '/collections/lighting/',
  'dining-table': '/collections/dining-tables/',
  dining: '/collections/dining-room/',
  table: '/collections/dining-room/',
}

const checkForRedirect = (pathname: string): string => {
  const foundDuplicate = Object.keys(duplicateRoutes).find(key => pathname.includes(key) && key)
  if (foundDuplicate) {
    const handle = pathname.split(foundDuplicate)[1].replace('/', '')
    return `${duplicateRoutes[foundDuplicate]}/${handle}`
  }

  const foundCollection = Object.keys(collectionsRoutes).find(key => pathname.includes(key) && key)

  if (foundCollection) {
    return collectionsRoutes[foundCollection]
  }

  if (pagesRoutes[pathname]) {
    return pagesRoutes[pathname]
  }

  return '/'
}

interface QueryData {
  site: {
    siteMetadata: {
      siteUrl: string
    }
  }
}

function NotFoundPage(props: PageProps) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={(data: QueryData) => {
        const redirect = checkForRedirect(props.location.pathname)
        return (
          <Helmet>
            <meta
              httpEquiv="refresh"
              content={`0;url=${data.site.siteMetadata.siteUrl}${redirect}`}
            />
          </Helmet>
        )
      }}
    />
  )
}

export default NotFoundPage
